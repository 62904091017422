import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { IconPlus, IconMinus } from "@tabler/icons"

interface FAQItemProps {
  opened?: boolean | undefined
  title: string
}

interface FAQItemState {
  opened: boolean | undefined
}

class FAQItem extends React.Component<FAQItemProps, FAQItemState> {
  constructor(props: FAQItemProps) {
    super(props)
    this.state = {
      opened: props.opened,
    }
  }

  toggle = () => {
    this.setState({ opened: !this.state.opened })
  }

  render = () => {
    return (
      <li>
        <div className="py-8 border-b">
          <div className="flex justify-between items-center">
            <h3
              className="mr-4 text-xl font-bold font-heading cursor-pointer"
              onClick={this.toggle}
            >
              {this.props.title}
            </h3>
            <span className="flex-none">
              {this.state.opened ? (
                <IconMinus className="inline" />
              ) : (
                <IconPlus className="inline" />
              )}
            </span>
          </div>
          <div
            className={
              (this.state.opened ? "text-black pt-8 pr-20" : "hidden") +
              " my-5 "
            }
          >
            {this.props.children}
          </div>
        </div>
      </li>
    )
  }
}

const FAQPage = () => (
  <Layout>
    <Seo title="FAQ" />

    <section className="relative max-w-7xl mx-auto overflow-x-hidden py-20">
      <div className="container mx-auto px-4">
        <h2 className="mt-4 font-bold font-heading text-5xl">
          Domande frequenti
        </h2>
        <div className="pt-20 flex flex-wrap -mx-8">
          <div className="w-full lg:w-1/2 px-8 mb-12 lg:mb-0">
            <h2 className="pb-10 font-bold font-heading text-3xl">
              Domanda generale
            </h2>
            <div className="mb-20 py-px w-64 bg-yellow-800"></div>
            <ul>
              <FAQItem
                title="Sed eget accumsan ipsum &amp; praesent dignissim?"
                opened
              >
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Curabitur ante dui, viverra quis blandit?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Sed eget accumsan ipsum &amp; praesent dignissim?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Sed eget accumsan ipsum &amp; praesent dignissim?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 px-8">
            <h2 className="pb-10 font-bold font-heading text-3xl">
              Ordini e resi
            </h2>
            <div className="mb-20 py-px w-64 bg-yellow-800"></div>
            <ul>
              <FAQItem title="Sed eget accumsan ipsum &amp; praesent dignissim?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Curabitur ante dui, viverra quis blandit?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Sed eget accumsan ipsum &amp; praesent dignissim?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
              <FAQItem title="Sed eget accumsan ipsum &amp; praesent dignissim?">
                <p>
                  After curabitur non tortor quis mauris vestibulum imperdiet
                  pellentesque et est. Suspendisse lorem sit amet vehicula.
                </p>
              </FAQItem>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FAQPage
